import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <React.Fragment>
      <div className="banner-section" id="home">
        <div className="corner-shape-left"></div>
        <div className="corner-shape-right"></div>
        <div className="banner text-center" data-aos="fade-up">
          <p>
            Unlock Your Business Potential with Seamless <br />
            HR Management Services and Cutting-Edge IT Solutions.
          </p>
          <h1>
            IT Solutions &<br />
            Hr Services
          </h1>
          <Link
            to="/"
            className="learn-btn"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({
                top: document.querySelector("#contact").offsetTop,
                behavior: "smooth",
              });
            }}
          >
            Learn More
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Banner;

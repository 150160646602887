import React from "react";
// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer">
        <div className="footer-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-3 col-lg-5">
                <div className="footer-logo">
                  <h4>Mcsoft</h4>
                  <p>
                    We work with a passion of taking challenges and creating new
                    ones in advertising sector.
                  </p>
                  <div className="footer-social-icons">
                    <ul>
                      <li>
                        <a
                          href="https://twitter.com/mcsoftinno40909"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/Mcsoft-innovations-Pvt-ltd-104249894939013"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/mcsoft-innovations-b05903202/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/mcsoftinnovations/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3">
                <div className="footer-page">
                  <h5>Links</h5>
                  <ul>
                    <li>
                      <a href="#about">About</a>
                    </li>
                    <li>
                      <a href="#services">Service</a>
                    </li>
                    <li>
                      <a href="#contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-4">
                <div className="footer-page">
                  <h5>Contact</h5>
                  <ul>
                    <li>
                      <i className="fa-solid fa-phone-volume me-3"></i>
                      (+91) 172-4360000
                    </li>
                    <li>
                      <i className="fa-solid fa-envelope me-3"></i>
                      mcsoftinnovations@gmail.com
                    </li>
                    <li>
                      <i className="fa-solid fa-location-dot me-3"></i>
                      E-202, Phase- 8-B, Industrial Area, 160055
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Footer;

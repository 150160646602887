/* eslint-disable no-unreachable */
/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  function string_length(s) {
    return s.split(" ").join("").length;
  }

  const handleName = (e) => {
    const name = e.target.value;
    const onlyLettersRegex = /^[A-Za-z\s]+$/;
    if (onlyLettersRegex.test(name) || name === "") {
      setNameError(false);
      setForm({ ...form, name });
    }
  };
  const handleNumber = (e) => {
    let phone = e.target.value;
    const onlyDigitsRegex = /^[0-9]+$/;
    if (onlyDigitsRegex.test(phone) || phone === "") {
      phone = phone.slice(0, 10);
      setPhoneError(false);
      setForm({ ...form, phone });
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    console.log("input");
    if (name === "email") setEmailError(false);
    if (name === "subject") setSubjectError(false);
    if (name === "message") setMessageError(false);
    setForm({ ...form, [name]: value });
  };
  const validateForm = () => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (form.name.split(" ").join("").length === 0) setNameError(true);
    if (string_length(form.email) === 0 || !emailRegex.test(form.email))
      setEmailError(true);
    if (string_length(form.phone) === 0 || form.phone.length !== 10)
      setPhoneError(true);
    if (string_length(form.subject) === 0) setSubjectError(true);
    if (string_length(form.message) === 0) setMessageError(true);

    if (!emailRegex.test(form.email)) {
      return false;
      setEmailError(true);
    }
    if (form.phone.length !== 10) {
      return false;
      setPhoneError(true);
    }

    return !(
      nameError ||
      emailError ||
      phoneError ||
      subjectError ||
      messageError
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      toast.success("Message has been sent successfully!");
      setForm({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      setNameError(false);
      setEmailError(false);
      setPhoneError(false);
      setSubjectError(false);
      setMessageError(false);
    } else {
      console.log("error in form details", form);
    }
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="contact-section mb-5" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="contact-content about-your-company">
                <h6>Contact With Us</h6>
                <h2 className="mb-4">
                  Feel Free to Get <br /> in Touch
                </h2>
                <div className="d-flex align-items-center mb-3 gap-3">
                  {/* <i className="fa-solid fa-phone-volume"></i> */}
                  <dotlottie-player
                    className="phone-contact"
                    src="https://lottie.host/b147e9c6-12ed-480d-a8c0-8c765b20279c/PBMAE2gdaI.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></dotlottie-player>
                  <div className="">
                    <p>Call Anytime</p>
                    <h5>
                      <b>(+91) 172-4360000</b>
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3 gap-3">
                  <dotlottie-player
                    className="mail-contact"
                    src="https://lottie.host/da625a15-e2bb-4b72-b020-5828dac4321d/6eBoG27DZJ.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></dotlottie-player>
                  <div className="">
                    <p>Send Email</p>
                    <h5>
                      <b>mcsoftinnovations@gmail.com</b>
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3 gap-3">
                  <dotlottie-player
                    className="address-contact"
                    src="https://lottie.host/4cd456ba-a272-45ba-b737-927175401416/9P5tN9f9UJ.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></dotlottie-player>
                  <div className="">
                    <p>Visit Now</p>
                    <h5>
                      <b>E-202, Phase- 8-B, Industrial Area, 160055</b>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-8 col-lg-8">
              <div className="contact-background d-flex">
                <form
                  className="form"
                  // style={{ margin: "auto" }}
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <input
                          type="text"
                          className="input"
                          placeholder="Your Name"
                          value={form.name}
                          onChange={handleName}
                        />
                        {nameError && (
                          <p class="help-block">This field is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <input
                          type="text"
                          className="input"
                          placeholder="Enter Email"
                          name="email"
                          value={form.email}
                          onChange={handleInput}
                        />
                        {emailError && (
                          <p class="help-block">Kindly enter a valid Email</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <input
                          type="text"
                          className="input"
                          placeholder="Phone Number"
                          value={form.phone}
                          onChange={handleNumber}
                        />
                        {phoneError && (
                          <p class="help-block">
                            Kindly enter a valid Phone Number
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <input
                          type="text"
                          className="input"
                          placeholder="Subject"
                          name="subject"
                          value={form.subject}
                          onChange={handleInput}
                        />
                        {subjectError && (
                          <p class="help-block">This field is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-4">
                        <textarea
                          type="text"
                          className="input"
                          placeholder="Write a Message"
                          name="message"
                          value={form.message}
                          onChange={handleInput}
                        />
                        {messageError && (
                          <p class="help-block">This field is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <button type="submit">Send a Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Contact;

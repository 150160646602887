import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import navcall from '../../assets/images/nav_call.png'

const Header = () => {
  const [sticky, setSticky] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 60 ? "sticky" : "";
    setSticky(stickyClass);
  };

  return (
    <React.Fragment>
      <div className={`header ${sticky}`}>
        <nav className={`navbar navbar-expand-lg py-0`}>
          <div className="container-fluid px-0">
            <Link
              className="navbar-brand"
              to="/"
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo({
                  top: document.querySelector("#home").offsetTop,
                  behavior: "smooth",
                });
              }}
            >
              Mcsoft
            </Link>
            <button
              className="navbar-toggler me-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        top: document.querySelector("#about").offsetTop,
                        behavior: "smooth",
                      });
                    }}
                    title="About"
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        top: document.querySelector("#services").offsetTop,
                        behavior: "smooth",
                      });
                    }}
                    title="Services"
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        top: document.querySelector("#contact").offsetTop,
                        behavior: "smooth",
                      });
                    }}
                    title="Contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              <div className="navbar-right">
                {/* <div>
                                    <img src={navcall} alt="navcall" className="img-fluid" />
                                </div>
                                <div>
                                    <small>
                                        Call Anytime
                                    </small>
                                    <p>
                                        <b>(+91) 172-4360000</b>
                                    </p>
                                </div> */}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};
export default Header;

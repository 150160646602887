import React from "react";
import hrImage from "../../assets/images/hr.png";

const HrSolution = () => {
  return (
    <React.Fragment>
      <div className="hr-solution mb-3">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-6 col-md-6 col-lg-6"
              data-aos="fade-up-right"
            >
              <div className="hr-solution-image p-3">
                <img
                  src={hrImage}
                  alt={hrImage}
                  className="img-fluid hrimage"
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6" data-aos="fade-up-left">
              <div className="hr-solution-content p-3">
                <div className="d-flex align-items-end gap-2 mb-3">
                  <h2>HR</h2>
                  <h4>Services</h4>
                </div>
                <div className="mb-3">
                  <p>
                    In today's highly competitive business scenarios,
                    organizations are continually seeking ways to gain a
                    competitive edge. Here effective human resource management
                    can be one crucial aspect that can contribute significantly
                    to an organization's success. <br />
                    However, it can be a complex task for businesses of all
                    sizes; this is where outsourcing of HR management services
                    comes into play that can deliver business advantage to your
                    organization.
                  </p>
                </div>
                <div className="mb-3">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div className="">
                      <h5>Streamlined Recruitment and Hiring Services:</h5>
                      <p>
                        We facilitate in workforce sourcing process, HR
                        verification, Employment contracts management and
                        Employee data management.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-briefcase"></i>
                    </div>
                    <div className="">
                      <h5>Compliance Management Services</h5>
                      <p>
                        We provide complaince management services like
                        consulting on employee compensation & remuneration
                        structures and statutory benefits.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-chart-line"></i>
                    </div>
                    <div className="">
                      <h5>Employee Training and Development</h5>
                      <p>
                        We provide a wide range of IT-related training programs
                        to enhance the skills and expertise of individuals and
                        organizations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default HrSolution;

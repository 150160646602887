import React from "react";
import analysis from "../../assets/images/analysis-comparison.svg";
import corptax from "../../assets/images/corp-tax-payment.svg";
import Programs from "../../assets/images/programs.svg";
import Research from "../../assets/images/research.svg";
import ItCard from "../../components/ItCard";
import icons01 from "../../assets/images/it01.svg";
import icons02 from "../../assets/images/it02.svg";
import icons03 from "../../assets/images/it03.svg";
import icons04 from "../../assets/images/it04.svg";
import HrCard from "../../components/HrCard";

const Services = () => {
  return (
    <React.Fragment>
      <div className="services-section" id="services">
        <div className="">
          <div className="service-tabs">
            <div className="service-tabs-section">
              <div className="service-heading">
                <div className="tabs-section_1">
                  <div className="service-heading mb-3">
                    <h3>Our Exclusive Our Services</h3>
                  </div>
                  <div
                    className="nav nav-pills justify-content-center me-3"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <div
                      className="link-active text-start active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      IT Solution
                    </div>
                    <div
                      className="link-active text-start"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      HR Solution
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                  tabIndex="0"
                >
                  <div className="card-background-overlay">
                    <div className="container">
                      <div className="">
                        <div className="row">
                          <ItCard
                            aos="fade-up-right"
                            svgImage={icons01}
                            title="Product Development"
                            subTitle="We focus on designing, testing and refining products for clients."
                          />
                          <ItCard
                            aos="zoom-in-up"
                            svgImage={icons02}
                            title="Digital Marketing"
                            subTitle="We specialize in crafting, executing, and optimizing digital marketing strategies."
                          />
                          <ItCard
                            aos="zoom-in-up"
                            svgImage={icons03}
                            title="Security System"
                            subTitle="We have designed, implemented and maintain security systems for clients."
                          />
                          <ItCard
                            aos="fade-up-left"
                            svgImage={icons04}
                            title="UI/UX Designing"
                            subTitle="We have succesfully crafted intuitive and visually appealing user interfaces."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                  tabIndex="0"
                >
                  <div className="card-background-overlay">
                    <div className="container">
                      <div className="">
                        <div className="row">
                          <HrCard
                            aos="fade-up-right"
                            title="Research"
                            subTitle="We specialize in providing comprehensive HR solutions based on research."
                            svgImage={Research}
                          />
                          <HrCard
                            aos="zoom-in-up"
                            title="Corporate Programs"
                            subTitle="We have tailored various corporate programs to meet your company's needs."
                            svgImage={Programs}
                          />
                          <HrCard
                            aos="zoom-in-up"
                            title="Analysis"
                            subTitle="We believe in conducting thorough analysis to provide actionable insights."
                            svgImage={analysis}
                          />
                          <HrCard
                            aos="fade-up-left"
                            title="Payroll"
                            subTitle="We have made it easier to manage payroll efficienty for businesses of all sizes."
                            svgImage={corptax}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Services;

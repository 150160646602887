import React from "react";
import itImage from "../../assets/images/it.svg";

const ItSolution = () => {
  return (
    <React.Fragment>
      <div className="hr-solution mb-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="hr-solution-content p-3" data-aos="fade-up-right">
                <div className="d-flex align-items-end gap-2 mb-3">
                  <h2>IT</h2>
                  <h4>Services</h4>
                </div>
                <div className="mb-3">
                  <p>
                    At McSoft Innovations, we pride ourselves on delivering
                    customised and client-centric IT and IT-enabled business
                    solutions to organisations of all sizes i.e Start-ups / SMEs
                    to large enterprises & government entities; we are dedicated
                    to addressing your unique business needs. <br />
                    We offer our services for Website development, software
                    development, network infrastructure, cyber security, digital
                    transformation initiatives and data management.
                  </p>
                </div>
                <div className="mb-3">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-desktop"></i>
                    </div>
                    <div className="">
                      <h5>Software Development</h5>
                      <p>
                        This includes Website Development, Android & iOS based
                        Mobile Applications developments.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-database mx-1"></i>
                    </div>
                    <div className="">
                      <h5>IT Consulting</h5>
                      <p>
                        We provide IT facility management services and IT
                        Infrastructure services along with BPO & KPOs
                        operations.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-code"></i>
                    </div>
                    <div className="">
                      <h5>Data Processing Services</h5>
                      <p>
                        We provide Data digitisation services, Data storage,
                        retrieval and data security services.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="mb-3">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-desktop"></i>
                    </div>
                    <div className="">
                      <h5>Web Development</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus magna orci, vehicula et dictum sit amet,
                        mattis non magna.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-database"></i>
                    </div>
                    <div className="">
                      <h5>Database Analysis</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus magna orci, vehicula et dictum sit amet,
                        mattis non magna.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div>
                      <i className="fa-solid fa-code"></i>
                    </div>
                    <div className="">
                      <h5>UX/UI Strategy</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus magna orci, vehicula et dictum sit amet,
                        mattis non magna.
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6" data-aos="fade-up-left">
              <div className="hr-solution-image p-3">
                <img src={itImage} alt={itImage} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ItSolution;

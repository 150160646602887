import React from "react";

const HrCard = ({ title, subTitle, svgImage, aos }) => {
    return (
        <React.Fragment>
            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0" data-aos={`${aos}`}>
                <div className="card">
                    <div className="rounded-images">
                        <img src={svgImage} alt={svgImage} />
                    </div>
                    <h5>{title}</h5>
                    <p>{subTitle}</p>
                </div>
            </div>
        </React.Fragment>
    )
}
export default HrCard;
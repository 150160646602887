import React, { useEffect } from "react";
import Layouts from "../../layouts";
import Banner from "../banner";
import ITHRSolution from "../IT&HrSolution";
import HrSolution from "../HrSolution";
import ItSolution from "../ItSolution";
import Services from "../Services";
import Contact from "../Contact";
import Aos from "aos";
import "aos/dist/aos.css";



const Home = () => {
    useEffect(() => {
        Aos.init({
            duration: 500,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <React.Fragment>
            <Layouts>
                <Banner />
                <ITHRSolution />
                <ItSolution />
                <HrSolution />
                <Services />
                <Contact />
            </Layouts>
        </React.Fragment>
    )
}
export default Home
import React from "react";
import About01 from "../../assets/images/it-hr.png";

const ITHrSolution = () => {
  return (
    <React.Fragment>
      <div className="it-solution mb-2 mb-lg-3" id="about">
        <div className="container">
          <div className="solution">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 mb-3 mb-lg-0 text-center ps-5">
                <img
                  src={About01}
                  alt={About01}
                  className="img-fluid"
                  data-aos="fade-up-right"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mb-3 mb-lg-0">
                <div className="about-your-company" data-aos="fade-up-left">
                  <h6>About Your Company</h6>
                  <h2>We're Partner of Your Innovations</h2>
                  <p>
                    At McSoft Innovations, we specialize in delivering
                    comprehensive HR management services and innovative IT
                    solutions tailored to meet the unique needs of your
                    business. <br /> In addition to exceptional human resource
                    services, we also offer state-of-the-art IT solutions
                    designed to transform the way you do business. Our team of
                    tech experts will leverage the latest technologies to
                    optimize your IT systems, enhance productivity, and secure
                    your digital assets.
                  </p>
                  <div className="d-block  d-md-flex justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <dotlottie-player
                        src="https://lottie.host/66f09630-3828-4480-bae1-778ea7dc4e51/KXfjcNjxaO.json"
                        background="transparent"
                        speed="1"
                        style={{ width: "100px" }}
                        loop
                        autoplay
                      ></dotlottie-player>
                      <h5>
                        It <br /> Solutions
                      </h5>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <dotlottie-player
                        src="https://lottie.host/f5c15dce-14f0-4e35-beb7-8d543311f31d/kKmiAOkKSe.json"
                        background="transparent"
                        speed="1"
                        style={{ width: "75px" }}
                        loop
                        autoplay
                      ></dotlottie-player>
                      <h5>
                        Hr <br /> Services
                      </h5>
                    </div>
                  </div>
                  <div className="mb-3">
                    <ul>
                      <li>
                        <i className="fa-solid fa-check"></i>Bringing new IT
                        solutions to the market
                      </li>
                      <li>
                        <i className="fa-solid fa-check"></i>To be included the
                        list of the best 100 IT companies
                      </li>
                      <li>
                        <i className="fa-solid fa-check"></i>Our company have
                        300 IT senior professionals.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a className="learn-btn" href="/">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ITHrSolution;
